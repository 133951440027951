
import { Vue, Component, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component({})
export default class MuvekkilTableInfo extends Vue {

  icraSevkTarihleri: any = [];

  async takipDosyaGetir() {
    this.icraSevkTarihleri = [];
    const response = await this.$http.post('/api/v1/takip-dosya-getir', {
      "muvekkil_id": [1],
      "baslangicTarihi": "2021-01-23",
      "bitisTarihi": "2023-09-23"
    });

    response.data.forEach((item: { rapor: any[] }) => {
      item.rapor.forEach((tarihler: any) => {
        const icraSevkTarihi = tarihler.icra_sevk_tarih;
        console.log(tarihler.tum_tarihler[0][2].dosya_sayisi, 'dssdsd')
        this.icraSevkTarihleri.push({
          name: icraSevkTarihi.toString(),
          takipSayısı: tarihler.takip_sayisi,
          derdest: tarihler.derdest,
          kendiDönemi: tarihler.kendi_donem_ici_kapatma_orani,
          ilkİki: tarihler.iki_donem_ici_kapatma_orani,
          tümDönemler: tarihler.tum_donemler_kapatma_orani,
          20132017: tarihler.tum_tarihler[0][0].dosya_sayisi,
          2018: tarihler.tum_tarihler[0][1].dosya_sayisi,

        });
      });
      console.log(item);
    });
  }

  headers = [
    {
      text: 'İCRA SEVK TARİHİ',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'TAKİP SAYISI', value: 'takipSayısı' },
    { text: '2013-2017', value: '20132017' },
    { text: '2018', value: '2018' },
    { text: '2019', value: '2019' },
    { text: '2020', value: '2020' },
    { text: '2021', value: '2021' },
    { text: '2022', value: '2022' },
    { text: '2023', value: '2023' },
    { text: 'DERDEST', value: 'derdest' },
    { text: 'KENDİ DÖNEMİ İÇERİSİNDE DOSYA KAPATMA ORANI', value: 'kendiDönemi' },
    { text: 'İLK 2 DÖNEM İÇERİSİNDE DOSYA KAPATMA ORANI', value: 'ilkİki' },
    { text: 'TÜM DÖNEMLER DOSYA KAPATMA ORANI', value: 'tümDönemler' },
  ];

  @Watch("$route")
  onRouteChange(to: Route, from: Route) {
    if (to.path === "/ofispro/tahsilat-raporlama-tipleri") {

    } else {
      this.headers = [];
    }
  }
}
