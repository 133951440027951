<template>
  <v-row>
    <v-col cols="12">
      <sayfa-aciklama
        :baslik="'İstatistikler'"
        :alt-baslik="
          'İstatistikler, tahsilat performansını ve henüz tahsil edilmeyen\n' +
          '        miktarları göstererek bilgi sunar.'
        "
      />
    </v-col>
    <v-col>
      <v-tabs
        background-color="transparent"
        class="slider-basliklar font-weight-600"
        v-model="tabs"
      >
        <v-tabs-slider color="#5FB3F6"></v-tabs-slider>
        <v-tab
          @click="handleButtonClick(button)"
          class="button-style text-center"
          :class="{ 'active-button': isButtonActive(button) }"
          v-for="button in buttons"
          :key="button.label"
        >
          {{ button.label }}
          <v-col v-if="button.label === 'Karşılaştırmalı Tahsilatlar'" cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="17" color="#5D7285" dark v-bind="attrs" v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <span
                >Seçilen iki yılın karşılaştırmasıdır, yıllar arası
                karşılaştırma yapılamaz.(Örn: 2022 ile 2024 tahsilatları)
              </span>
            </v-tooltip>
          </v-col>
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import SayfaBilgiInfo from "@/components/SayfaBilgiInfo.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: { SayfaAciklama, SayfaBilgiInfo },
})
export default class UstBasliklar extends Vue {
  tabs = 0;

  buttons = [
    {
      label: "Genel İstatistik",
      route: "/ofispro/genel-istatistikler",
    },
    {
      label: "Tahsilatlar",
      route: "/ofispro/tahsilatlar",
    },
    {
      label: "Karşılaştırmalı Tahsilatlar",
      route: "/ofispro/karsilastirmali-tahsilatlar",
    },
  ];

  mounted() {
    this.setActiveTab();
  }

  setActiveTab() {
    const currentRoute = this.$route.path;

    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];

      if (button.route && currentRoute.startsWith(button.route)) {
        this.tabs = i;
        return;
      }
    }
  }

  isButtonActive(button) {
    const currentRoute = this.$route.path;

    if (button.route && currentRoute === button.route) {
      return true;
    }

    return false;
  }

  handleButtonClick(button) {
    if (button.route) {
      this.$router.push(button.route);
    }
  }
}
</script>